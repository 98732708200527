import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {list, reset} from '../../actions/productUpdate/list';

class List extends Component {
  static propTypes = {
    product: PropTypes.string.isRequired,
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.product &&
      decodeURIComponent(this.props.product)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.product !== nextProps.product)
      nextProps.list(
        nextProps.product &&
        decodeURIComponent(nextProps.product)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    return (
      <div>
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}

        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
          <div className={'table-wrapper'}>
            <table className="table table-responsive table-hover table-products-update">
              <thead>
              <tr>
                <th>ID</th>
                <th>Process</th>
                <th>Address1</th>
                <th>Address2</th>
                <th>Zip</th>
                <th>City</th>
                <th>Country</th>
                <th>Created</th>
                <th>User</th>
              </tr>
              </thead>
              <tbody>
              {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map(item => (
                <tr key={item['@id']}>
                  <td>{item['id']}</td>
                  <td>{item['process']}</td>
                  <td>{item['address1']}</td>
                  <td>{item['address2']}</td>
                  <td>{item['zip']}</td>
                  <td>{item['city']}</td>
                  <td>{item['country']}</td>
                  <td>{item['created']}</td>
                  <td>{item['user'] ? item['user']['email'] : ''}</td>
                </tr>
              ))}
              </tbody>
            </table>

            {/*{this.pagination()}*/}
          </div>
      </div>
    );
  }

  pagination() {
    const view = this.props.retrieved && this.props.retrieved['hydra:view'];
    if (!view) return;

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last
    } = view;

    if (!(first || previous || next || last)) {
      return;
    }

    return (
      <nav aria-label="Page navigation">
        <Link
          to="."
          className={`btn btn-nav${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&laquo;</span> First
        </Link>
        <Link
          to={
            !previous || previous === first ? '.' : encodeURIComponent(previous)
          }
          className={`btn btn-nav${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&lsaquo;</span> Previous
        </Link>
        <Link to="/public/products/" className="btn btn-nav btn-back">
          Back to list
        </Link>
        <Link
          to={next ? encodeURIComponent(next) : '#'}
          className={`btn btn-nav${next ? '' : ' disabled'}`}
        >
          Next <span aria-hidden="true">&rsaquo;</span>
        </Link>
        <Link
          to={last ? encodeURIComponent(last) : '#'}
          className={`btn btn-nav${next ? '' : ' disabled'}`}
        >
          Last <span aria-hidden="true">&raquo;</span>
        </Link>
      </nav>
    );
  }

}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource
  } = state.productUpdate.list;
  return {retrieved, loading, error, eventSource};
};

const mapDispatchToProps = dispatch => ({
  list: page => {
    dispatch(list(page))
  },
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
