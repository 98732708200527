import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {retrieve, reset} from '../../actions/product/show';
import {List} from '../productUpdate/';

class Show extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const item = this.props.retrieved;

    return (
      <div className="product-table-wrapper">
        <div className={"product-page-bg-color"}/>
        <h1>Product</h1>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}

        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}

        {item && (
          <div className={"product-info"}>
            <div className={"product-details-wrapper"}>
              <div className={"product-detail"}>ID: <span>{item['id']}</span></div>

              <div className={"product-detail"}>Name: <span>{item['name']}</span></div>

              <div className={"product-detail"}>Updates:</div>
            </div>

            <List product={item['@id']}/>
          </div>
        )}

        {/*<Link to="/public/products/" className="btn btn-primary">*/}
        {/*  Back to list*/}
        {/*</Link>*/}
      </div>
    );
  }

}

const mapStateToProps = state => ({
  retrieved: state.product.show.retrieved,
  error: state.product.show.error,
  loading: state.product.show.loading,
  eventSource: state.product.show.eventSource
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(Show);
